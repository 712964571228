import {Controller} from 'stimulus'
import Dropzone from 'dropzone'
Dropzone.autoDiscover = false

export default class extends Controller {
  // static targets = [ "input" ]
  connect() {
    this.dropZone = createDropZone(this)
    // this.hideFileInput()
    // this.bindEvents()
  }
  reset() {
    this.dropZone.removeAllFiles(true)
  }
  openSelect() {
    return this.dropZone.hiddenFileInput.click()
  }
  //
  // hideFileInput() {
  //   this.inputTarget.disabled = true
  //   this.inputTarget.style.display = "none"
  // }
  //
  // bindEvents() {
  //   this.dropZone.on("addedfile", (file) => {
  //     setTimeout(() => { file.accepted && createDirectUploadController(this, file).start() }, 500)
  //   })
  //
  //   this.dropZone.on("removedfile", (file) => {
  //     file.controller && removeElement(file.controller.hiddenInput)
  //   })
  //
  //   this.dropZone.on("canceled", (file) => {
  //     file.controller && file.controller.xhr.abort()
  //   })
  //
  //   this.dropZone.on("processing", (file) => {
  //     this.submitButton.disabled = true
  //   })
  //
  //   this.dropZone.on("queuecomplete", (file) => {
  //     this.submitButton.disabled = false
  //   })
  // }
  //
  get headers() {
    return {'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')}
  }
  //
  get url() {
    return jQuery(this.element)
      .find('form')
      .attr('action')
  }
  //
  // get maxFiles() { return this.data.get("maxFiles") || 1 }
  //
  // get maxFileSize() { return this.data.get("maxFileSize") || 256 }
  //
  // get acceptedFiles() { return this.data.get("acceptedFiles") }
  //
  // get addRemoveLinks() { return this.data.get("addRemoveLinks") || false }
  //
  // get form() { return this.element.closest("form") }
  //
  // get submitButton() { return findElement(this.form, "input[type=submit], button[type=submit]") }
}

function createDropZone(controller) {
  const dzEl = jQuery(controller.element).find('.dropzone')[0]
  return new Dropzone(dzEl, {
    url: controller.url,
    headers: controller.headers,
    createImageThumbnails: false,
    maxFiles: 1,
    maxFilesize: 256,
    timeout: 1000000,
    previewTemplate: document.getElementById('tpl').innerHTML,
    // chunking: true,
    // chunkSize: 500000,
    // retryChunks: true,
    // retryChunksLimit: 3,
    acceptedFiles: null,
    addRemoveLinks: false,
    autoQueue: true,
    init: function() {
      this.on('addedfile', function(file) {
        jQuery(this.element)
          .find('.dz-message')
          .addClass('hidden')
        jQuery(this.element)
          .find('.dz-upload-message')
          .empty()
          .html(I18n.t("caricamento"))
      })

      this.on('error', function(file, response) {
        $('#main-page-wrapper').html(response['html'])
        jQuery(this.element)
          .find('.dz-upload-message')
          .empty()
        jQuery.ajax({
          type: 'GET',
          dataType: 'script',
          accept: 'js',
          url: '/dashboard',
        })
      })

      this.on('success', function(file, response) {
        jQuery(this.element)
          .find('.dz-upload-message')
          .empty()
          .html(I18n.t('elaborazione_in_corso'))

        const event = new CustomEvent('start-countdown')
        window.dispatchEvent(event)

        jQuery.ajax({
          type: 'POST',
          dataType: 'script',
          accept: 'js',
          url: '/xrays/' + response['id'] + '/process_preview.js',
        })
      })
    }

    // addedfile: function(file) {  },
  })

}

// window.IMAGES_DROPZONE.on('success', function (file, response) {
//   var previewElement = file.previewElement,
//       width = file.width,
//       height = file.height;
//
//   var $replace = $(response.html);
//   $(previewElement).replaceWith($replace);
// });
