import {Controller} from 'stimulus'
// import * as cornerstone from "cornerstone-core";
// import * as dicomParser from "dicom-parser";
// import * as cornerstoneMath from "cornerstone-math";
// import * as cornerstoneTools from "cornerstone-tools";
// import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader" as ciccio;


// import * as dicomParser from 'dicom-parser/dist/dicomParser';

export default class extends Controller {
  static values = {dicom: String}
  // static targets = ['results', 'output']

  initialize() {
    this.dicom = this.element.dataset["dicom"];

  }

  connect() {
    window.DicomZoom = this
  }

  start() {
      // base function to get elements
    dwv.gui.getElement = dwv.gui.base.getElement;
    // create the dwv app
    var app = new dwv.App();
    // initialise with the id of the container div
    app.init({
        containerDivId: "dwv",
        tools : {
          ZoomAndPan: {}
        } // or try "ZoomAndPan"
    });

    // activate tool once done loading
    app.addEventListener("load", function () {
      $(".hide-on-dicom-load").remove()
      app.setTool("ZoomAndPan");
    });
    // load dicom data
    app.loadURLs([this.dicom]);
  }

  setStringOutput() {

  }

  reset() {

  }

}
