import {Controller} from 'stimulus'

export default class extends Controller {
  static values = {seconds: Number, str: String}
  static targets = ['results', 'output']

  initialize() {
    this.seconds = this.secondsValue
  }

  startCountDown() {
    const timer = setInterval(() => {
      this.seconds--
      this.setStringOutput()
      if (this.seconds === 0) {
        clearInterval(timer)
      }
    }, 1000)
  }

  start() {
    this.startCountDown()
    this.setStringOutput()
  }

  setStringOutput() {
    this.outputTarget.textContent = this.strValue.replace('%n', this.seconds)
  }

  reset() {
    jQuery('.dz-message').removeClass('hidden')
  }
}
