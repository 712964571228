import { Controller } from "stimulus"
// import Dropzone from "dropzone"
// Dropzone.autoDiscover = false

export default class extends Controller {
  // static targets = [ "input" ]
  connect() {
    var el = jQuery(this.element);

    el.append('<input class="hidden-submit-input hidden" style="display: none" type="submit" name="commit" value="Aggiorna" data-disable-with="Aggiorna">')

    if(el.data("submit-on-change")){
      el.on("change", function() {
        $(this).find("input.hidden-submit-input").click();
      });
    }
  }

}
